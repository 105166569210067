<template>
  <div id="main" class="pa-4" v-resize="onResize">
    <v-overlay :value="OverlayProses">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         <label> Mohon Tunggu</label>
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="red darken-4"
            indeterminate
            rounded
            height="12"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-overlay>
    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-alert-outline"
      v-model="AlertTglPayroll"
      transition="slide-x-transition"
    >
      {{pesan}}
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <!-- dialog info etc -->
    <v-dialog
      v-model="dialogOffDay"
      max-width="300"
    >
      <v-card class="rounded-lg">
        <v-toolbar flat height="65">
          <v-toolbar-title class="title">
            <span class="text-h5 mx-1">Filter Tanggal</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialogOffDay = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        
        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg pa-5">     
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    label="Tanggal Off"
                    color="dark"
                    type="date"
                    dense
                    v-model="DateOff"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                    v-model="KeteranganOff"
                    :items="['OFF','LB','L']"
                    dense
                    label="Keterangan"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            dark
            depressed
            color="red darken-4 mx-n2"
            class="text-capitalize"
            @click="OffKanDay"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of dialog info etc -->

    <!-- dialog info etc -->
    <v-dialog
      v-model="dialogInfo"
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Informasi
        </v-card-title>
        
        <v-card-text>
          <div cols="3" md="3"><label class="mx-2" style="background-color:red;">ass</label></div>
          <div cols="3" md="3"><label class="mx-2" style="background-color:blue;">assx</label></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of dialog info etc -->

    <!-- <v-container fluid v-show="this.valid == true"> -->
    <v-card
      outlined
      class="pa-1 elevation-1 rounded-lg"
    >
      <v-row
        dense
        class="mt-1 ml-1 mr-1"
      >
        <v-col cols="12" sm="6" md="3" lg="3" class="mb-n5">
          <v-select
            label="Bulan"
            class="rounded-lg"
            v-model.lazy="PeriodeBulan"
            color="dark"
            :items="AllBulan"
            :item-text="TextNamaBulan"
            item-value="Bulan"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3" lg="3" class="mb-n5">
          <v-select
            label="Tahun"
            v-model="PeriodeTahun"
            class="rounded-lg"
            color="dark"
            :items="AllTahun"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col :cols="mobile ? '12' : true" class="shrink mb-2">
          <v-btn text block outlined height="40" @click="getPeriode" class="text-capitalize">
            <v-icon left>mdi-text-box-search</v-icon>
            Proses
          </v-btn>
        </v-col>

        <v-divider inset vertical class="ma-3 mt-1" v-show="mobile == false"></v-divider>

        <v-spacer></v-spacer>

        <v-col :cols="mobile ? '12' : true" class="shrink mb-2">
          <v-btn text block outlined height="40" @click="offday" class="text-capitalize">
            <v-icon left>mdi-printer</v-icon>
            Day Off
          </v-btn>
        </v-col>

        <v-col :cols="mobile ? '12' : true" class="shrink mb-2" v-show="this.KeteranganAbsen.length > 0">
          <v-btn text block outlined height="40" @click="cetakexcel('container','tes')" class="text-capitalize">
            <v-icon left>mdi-printer</v-icon>
            Excel
          </v-btn>
        </v-col>

        <v-col :cols="mobile ? '12' : true" class="shrink mb-2" v-show="this.KeteranganAbsen.length > 0">
          <v-btn text block outlined height="40" @click="cetakpdf" class="text-capitalize">
            <v-icon left>mdi-printer</v-icon>
            Cetak
          </v-btn>
        </v-col>

        <v-col :cols="mobile ? '12' : true" class="shrink mb-2" v-show="this.KeteranganAbsen.length > 0">
          <v-btn text block outlined height="40" @click="infotable" class="text-capitalize">
            <v-icon left>mdi-information-variant</v-icon>
            Info
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row
      dense
      class="mt-2"
      v-show="totalpage != 0"
    >
      <v-col cols="12" sm="5" md="4" lg="4" class="mb-n8">
        <v-text-field 
          solo
          height="51px"
          class="rounded-lg"
          color="dark"
          type="text" 
          @keyup.enter="pencarian(search)" 
          label="Pencarian Nama" 
          prepend-inner-icon="mdi-magnify" 
          :append-icon="search != '' ? 'mdi-close' : ''"
          @click:append="search = ''"
          v-model.lazy="search"
        >
          <template v-slot:append>
            <v-btn
              color="red darken-4"
              height="35"
              @click.native="pencarian(search)"
              :dark="search != '' ? true : false"
              depressed
              class="text-capitalize mr-n1"
              :disabled="search != '' ? false : true">Cari
            </v-btn>
          </template>
        </v-text-field>
      </v-col>

      <v-spacer></v-spacer>

      <v-layout
        class="mt-2 mb-10"
        :justify-end="mobile == false"
        :justify-center="mobile == true"
      >
      <div style="width: 200px;">
          <v-btn
          dense
          color="white"
          depressed
          class="text-capitalize mr-n1"
          @click="showallitem">
            Tampilkan semua
          </v-btn>
        </div>
        <div style="width: 350px;">
          <v-pagination
            v-show="totalpage != 0"
            color="red darken-4"
            v-model="halaman"
            @input="gantipage(halaman)"
            :length="totalpage"
          ></v-pagination>
        </div>
      </v-layout>
    </v-row>
    <!-- card pc tab and other -->
    <!-- <v-toolbar
      outlined
      height="67"
      class="rounded-lg elevation-1"
      v-show="mobile == false"
    >
      <div class="mt-7 ml-n1 mr-3">
        <v-select
          label="Bulan"
          class="rounded-lg"
          v-model.lazy="PeriodeBulan"
          color="dark"
          :items="AllBulan"
          :item-text="TextNamaBulan"
          item-value="Bulan"
          outlined
          dense
          style="max-width: 230px;"
        ></v-select>
      </div>

      <div class="mt-7">
        <v-select
          label="Tahun"
          v-model="PeriodeTahun"
          class="rounded-lg"
          color="dark"
          :items="AllTahun"
          outlined
          dense
          style="max-width: 230px;"
        ></v-select>
      </div>

      <v-card-actions>
        <v-btn text outlined class="text-capitalize subtitle-1" @click="getPeriode" color="dark">
          <v-icon class="mr-1">mdi-file-table</v-icon>Proses
        </v-btn>
      </v-card-actions>

      <v-divider inset vertical class="ml-2"></v-divider>

      <v-spacer></v-spacer>

      <v-card-actions class="mr-n3">
        <v-btn text outlined class="text-capitalize subtitle-1" v-show="this.KeteranganAbsen.length > 0" @click="cetakexcel('container','tes')" color="dark">
          <v-icon class="mr-1">mdi-printer</v-icon>Excel
        </v-btn>
        <v-btn text outlined class="text-capitalize subtitle-1" v-show="this.KeteranganAbsen.length > 0" @click="cetakpdf" color="dark">
          <v-icon class="mr-1">mdi-printer</v-icon>Cetak
        </v-btn>
        <v-btn text outlined class="text-capitalize subtitle-1" v-show="this.KeteranganAbsen.length > 0" @click="infotable" color="dark">
          <v-icon class="mr-1">mdi-information-variant</v-icon>Info
        </v-btn>
      </v-card-actions>
    </v-toolbar> -->

    <!-- end card pc tab -->

    <v-card class="mt-n6 rounded-lg" elevation="4" v-show="KeteranganAbsen.length > 0">
      <div @scroll="this.tes" class="pa-2" id="container">
        <div class="scroller-wrapper">
          <table id="tableketerangan" border="0" cellpadding="0" cellspacing="0" width="2565" style="border-collapse:collapse;">
            <!-- <v-simple-table dense
              height="300px">
            <template v-slot:default> -->
          <!-- table-layout:fixed;width:1923pt -->
          <!-- <colgroup>
          <col width="64" style="width:48pt">
          <col width="223" style="mso-width-source:userset;mso-width-alt:7936;width:167pt">
          <col width="294" style="mso-width-source:userset;mso-width-alt:10439;width:220pt">
          <col width="64" span="31" style="width:48pt">
          </colgroup> -->
          <thead>
            <tr height="19" style="height:14.4pt">
              <th colspan="8" rowspan="4" height="76" width="901" style="border-right:
              .5pt solid black;border-bottom:.5pt solid black;height:57.6pt;width:675pt"><center>ABSENSI
              HARIAN</center><span style="mso-spacerun:yes">&nbsp;</span></th>
              <th colspan="2" class="border-top warna-ungu" width="128" style="border-left:none;width:96pt">Periode
              :<span style="mso-spacerun:yes">&nbsp;</span></th>
              <th class="border-top warna-ungu" width="64" style="width:48pt" v-for="x in 12" :key="'C'+x">&nbsp;</th>
              <th colspan="2" class="border-top warna-ungu" width="128" style="width:96pt" v-text="PeriodeTextDariTanggal"></th>
              <th class="border-top warna-ungu" width="64" style="width:48pt">S/D</th>
              <th colspan="2" class="border-top warna-ungu" width="128" style="width:96pt" v-text="PeriodeTextSampaiTanggal"></th>
              <th class="border-top warna-ungu" width="64" style="width:48pt" v-for="c in 6" :key="'X'+c">&nbsp;</th>
              <th class="border-up-right warna-ungu" width="64" style="width:48pt">&nbsp;</th>
          </tr>
          <tr height="19" style="height:14.4pt">
            <th height="19" class="border-none" style="height:14.4pt;border-left:none" v-for="x in 25" :key="x">&nbsp;</th>
            <th class="border-mid-right"></th>
          </tr>
          <tr height="19" style="height:14.4pt">
            <th height="19" class="border-none" style="height:14.4pt;border-left:none" v-for="x in 25" :key="x">&nbsp;</th>
            <th class="border-mid-right"></th>
          </tr>
          <tr height="19" style="height:14.4pt">
            <th height="19" class="border-bottom" style="height:14.4pt;border-left:none" v-for="x in 25" :key="x">&nbsp;</th>
            <th class="border-bottom border-bottom-right">&nbsp;</th>
          </tr>

          <tr height="19" class="headertbl" style="height:14.4pt">
            <th rowspan="3" height="57" class="text-center" style="height:43.2pt;border-top:none">NO</th>
            <th rowspan="3" class="text-center" style="border-top:none">NAMA</th>
            <th rowspan="3" class="text-center" style="border-top:none">JABATAN</th>

            <th class="text-center" style="border-top:none;border-left:none" v-for="(hari,index) in DataHari" :key="index" v-text="hari"></th>
          
          </tr>
          <tr height="19" class="headertbl" style="height:14.4pt">

            <th height="19" class="text-center" style="border-top:none;" v-for="data in DataTanggalBulan" :key="'A'+data" v-text="data"></th>

          </tr>
          <tr height="19" class="headertbl" style="height:14.4pt">
            <th height="19" class="text-center" style="height:14.4pt;border-top:none;border-left:
            none" v-for="(n,index) in DataHari" :key="n+index">A</th>
          </tr>
          </thead>

          <tbody>
          <!-- <v-virtual-scroll
                :items="KeteranganAbsen"
                :item-height="50"
                width="500"
                height="300"
              >
            <template v-slot:default="{ item }"> -->
              <tr height="21" v-for="(item) in KeteranganAbsen" :key="item.id" style="height:15.6pt">
              <!-- <tr height="21" style="height:15.6pt"> -->
            <td height="21" class="text-center" style="height:15.6pt;border-top:none" v-text="item.No"></td>
            <td class="text-nama sidetbl" v-text="item.karyawan.Nama"></td>
            <td class="text-jabatan sidetbl" v-text="item.karyawan.jabatan.NamaJabatan"></td>
            <!-- <span v-show="TextEditor == false" v-text="item.tanggal[1]"/> -->
            <!-- <input type="text" class="TextEditor" v-show="TextEditor == true" @keyup.enter="SaveEnter(item)" @blur="SaveCol(item)" v-model="item.tanggal[1]"/> -->
            
            <!-- Get Tgl 26-TglAkhir BulanLalu -->
            <!-- <td class="text-center" v-for="(itemtgl1,index) in item.tanggal[PeriodeBulanLalu]" :key="'Hehe'+index"> -->
              <td :class="item.tanggal[PeriodeBulanLalu][index] == 'H' 
                  ? 'text-center warna-H' : item.tanggal[PeriodeBulanLalu][index] == 'A' 
                  ? 'text-center warna-A' : item.tanggal[PeriodeBulanLalu][index] == 'I'
                  ? 'text-center warna-I' : item.tanggal[PeriodeBulanLalu][index] == 'IR'
                  ? 'text-center warna-IR' : item.tanggal[PeriodeBulanLalu][index] == 'S'
                  ? 'text-center warna-S' : item.tanggal[PeriodeBulanLalu][index] == 'SD'
                  ? 'text-center warna-SD' : item.tanggal[PeriodeBulanLalu][index] == '1/2'
                  ? 'text-center warna-1-2' : item.tanggal[PeriodeBulanLalu][index] == 'S/B'
                  ? 'text-center warna-S-B' : item.tanggal[PeriodeBulanLalu][index] == 'CT'
                  ? 'text-center warna-CT' : item.tanggal[PeriodeBulanLalu][index] == 'L'
                  ? 'text-center warna-L' : item.tanggal[PeriodeBulanLalu][index] == 'LB'
                  ? 'text-center warna-LB' : item.tanggal[PeriodeBulanLalu][index] == 'OFF'
                  ? 'text-center warna-OFF' : item.tanggal[PeriodeBulanLalu][index] == 'R'
                  ? 'text-center warna-RESIGN' : 'text-center warna-null'" 
                  v-for="(itemtgl1,index) in item.tanggal[PeriodeBulanLalu]" :key="'Hehe'+index">
              <quick-edit type="text" :multiple="false" maxlength="3" @input="SaveCol(item,PeriodeBulanLalu,PeriodeTahunLalu,item.tanggal[PeriodeBulanLalu],item.tanggal[PeriodeBulanLalu][index],index)" :options="KeteranganList" :showButtons="false" :emptyText="' '" v-model.lazy="item.tanggal[PeriodeBulanLalu][index]">
              <template v-slot:default="{ value }">
                  <div width="10px" v-text="value"></div>
              </template>
                <!--  <div slot="button-ok" v-text="'✔'"></div>
                  <div slot="button-cancel" v-text="'✗'"></div> -->
              </quick-edit>
            </td>

            <!-- Get Tgl Bulan Ini - 25 -->
            <!-- <td class="text-center KonidisiWarna" v-for="(itemtgl2,index) in item.tanggal[PeriodeBulan]" :key="index"> -->
              <td :class="item.tanggal[PeriodeBulanIni][index] == 'H' 
                  ? 'text-center warna-H' : item.tanggal[PeriodeBulanIni][index] == 'A' 
                  ? 'text-center warna-A' : item.tanggal[PeriodeBulanIni][index] == 'I'
                  ? 'text-center warna-I' : item.tanggal[PeriodeBulanIni][index] == 'IR'
                  ? 'text-center warna-IR' : item.tanggal[PeriodeBulanIni][index] == 'S'
                  ? 'text-center warna-S' : item.tanggal[PeriodeBulanIni][index] == 'SD'
                  ? 'text-center warna-SD' : item.tanggal[PeriodeBulanIni][index] == '1/2'
                  ? 'text-center warna-1-2' : item.tanggal[PeriodeBulanIni][index] == 'S/B'
                  ? 'text-center warna-S-B' : item.tanggal[PeriodeBulanIni][index] == 'CT'
                  ? 'text-center warna-CT' : item.tanggal[PeriodeBulanIni][index] == 'L'
                  ? 'text-center warna-L' : item.tanggal[PeriodeBulanIni][index] == 'LB'
                  ? 'text-center warna-LB' : item.tanggal[PeriodeBulanIni][index] == 'OFF'
                  ? 'text-center warna-OFF' : item.tanggal[PeriodeBulanIni][index] == 'R'
                  ? 'text-center warna-RESIGN' : 'text-center warna-null'" 
                  v-for="(itemtgl2,index) in item.tanggal[PeriodeBulanIni]" :key="index">
              <quick-edit type="text" :multiple="false" @input="SaveCol(item,PeriodeBulanIni,PeriodeTahun,item.tanggal[PeriodeBulanIni],item.tanggal[PeriodeBulanIni][index],index)" :showButtons="false" maxlength="3" :options="KeteranganList" :emptyText="' '" v-model.lazy="item.tanggal[PeriodeBulanIni][index]">
              <template v-slot:default="{ value }">
                  <div width="10px" v-text="value"></div>
              </template>
                <!--  <div slot="button-ok" v-text="'✔'"></div>
                  <div slot="button-cancel" v-text="'✗'"></div> -->
              </quick-edit>
            </td>
          </tr>
            <!-- </template>
          </v-virtual-scroll> -->
          
          <!--[endif]-->
          </tbody>
        </table>
      <!-- </template>
    </v-simple-table> -->
        </div>
      </div>
    </v-card>
  <!-- </v-container> -->
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
// import html2PDF from 'jspdf-html2canvas';
// import html2canvas from 'html2canvas';
// import html2pdfjs from 'html2pdf.js'
// import jsPDF from "jspdf";
// import 'jspdf-autotable'
Vue.use(GridPlugin);

export default {
  data() {
    return {
      search:'',
      mobile:null,
      dialogOffDay:false,
      KeteranganOff:'',
      DateOff:'',
      totalpage:0,
      halaman:1,
      emptyText:null,
      isActive: false,
      AllBulan:[
        {Bulan:'1',NamaBulan:'Januari'},
        {Bulan:'2',NamaBulan:'Februari'},
        {Bulan:'3',NamaBulan:'Maret'},
        {Bulan:'4',NamaBulan:'April'},
        {Bulan:'5',NamaBulan:'Mei'},
        {Bulan:'6',NamaBulan:'Juni'},
        {Bulan:'7',NamaBulan:'Juli'},
        {Bulan:'8',NamaBulan:'Agustus'},
        {Bulan:'9',NamaBulan:'September'},
        {Bulan:'10',NamaBulan:'Oktober'},
        {Bulan:'11',NamaBulan:'November'},
        {Bulan:'12',NamaBulan:'Desember'}],
      AllTahun:[],
      PeriodeBulan:0,
      PeriodeBulanIni:0,
      PeriodeTahun:null,
      PeriodeTahunLalu:null,
      PeriodeBulanLalu:0,
      pesan:'',
      DataHari:[],
      DataTanggalBulan:[],
      scrolledY:null,
      scrolledX:null,
      RealKeteranganAbsen:[],
      KeteranganAbsen:[],
      KeteranganAbsenSelected:[],
      TextEditor:false,
      dariTanggal:'',
      domDariTanggal:'',
      sampaiTanggal:'',
      domSampaiTanggal:'',
      dialogInfo:false,
      payrolls: [],
      valid:null,
      textAktif:true,
      umenu:[],
      OverlayProses:false,
      templatepayroll:false,
      loading:true,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      PeriodeTextDariTanggal:null,
      PeriodeTextSampaiTanggal:null,
      MenuExport: false,
      KeteranganList:['H','A','I','IR','S','SD','1/2','S/B','CT','L','LB','OFF','R'],
      commands: [
        {
          buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons"}
        },
        {
          buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons"}
        }
      ],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertTglPayroll:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      form: new FormData,
      editedIndex: -1,
      toolbarOptions: ['Search'],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
  //  var $th = $('.container').find('thead th')
  // $('.container').on('scroll', function() {
  //   $th.css('transform', 'translateY('+ this.scrollTop +'px)');
  // });
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    this.GeneratorTahun()
    // this.getHari()
    // await Promise.all([this.saya()])
    // this.getdata()
  },

  computed: {
    formTitlePerusahaan() {
      return this.editedIndex === -1 ? 'Tambah Data Perusahaan' : 'Ubah Data Perusahaan'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    PeriodeBulan(){
      // console.log(this.KeteranganAbsen.length)
      // if (this.KeteranganAbsen.length > 0) {
      //   this.OverlayProses = true
      //   setTimeout(this.KeteranganAbsen = [], 2000)
        
      // }
    },
    dariTanggal(){
      var d = new Date(this.dariTanggal)
      const bulan = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      this.domDariTanggal = ('0' + d.getDate()).slice(-2) + '-' + bulan[d.getMonth()] + '-' + d.getFullYear().toString().substr(-2)
      // alert(this.dariTanggal)
      // if (this.dariTanggal && this.sampaiTanggal != '') {
      //   this.getpayroll()
      // }
    },
    sampaiTanggal(){
      var d = new Date(this.sampaiTanggal)
      const bulan = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      this.domSampaiTanggal = ('0' + d.getDate()).slice(-2) + '-' + bulan[d.getMonth()] + '-' + d.getFullYear().toString().substr(-2)
      // alert(this.dariTanggal)
      // if (this.dariTanggal && this.sampaiTanggal != '') {
      //   this.getpayroll()
      // }
    },
  
      // let a = this.DataPerusahaan.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahPerusahaan (val) {
      val || this.KeluarDialogPerusahaan()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
    AlertTglPayroll(){
      this.AlertItem()
    }
  },

  methods: {
    showallitem(){
      this.OverlayProses = true
      setTimeout(() => {
        this.KeteranganAbsen = this.getAllItem()
        this.OverlayProses = false
        }, 1500)
    },
    getAllItem(){
      return this.RealKeteranganAbsen
    },
    TextCaps(data){
      console.log(data)
    },
    OffKanDay(){
      api.put('/inputoff/?token='+this.token,{
        tanggal: this.DateOff,
        keterangan: this.KeteranganOff,
      }).then((res) => {
						if (!res) {
							//do nothing
						}else{
              if(this.KeteranganAbsen.length > 0){
                this.getPeriode()
              }else{
                this.OverlayProses = false
              }
              // this.getdata()
              // console.log(res.data)
              // this.AlertBerhasilTerubah = true
						}
						
					})
          this.OverlayProses = true
          this.dialogOffDay = false
          this.Toast('Data Di Save')
    },
    offday(){
      this.dialogOffDay = true
    },
    gantipage(hal){
      let paging = this.paginasi()
      this.KeteranganAbsen = paging[hal-1]
    },
    TextNamaBulan(x){
      return x.Bulan + ' — ' + x.NamaBulan
    },
    SaveEnter(item){
      console.log(item.length)
      // console.log('ee',this.KeteranganAbsenSelected)
      // this.TextEditor = false
    },
    RawInput(data){
      console.log(data)
    },
    SaveCol(item,bulan,tahun,obj,text,key){
      // console.log('item',item)
      // console.log('bulan',bulan)
      // console.log('bulan',tahun)
      // console.log('bulan',key)
      //  console.log('data',text)
      //  console.log('index',index)
      if (text == '' || text == null || this.KeteranganList.includes(text)) {
        this.Toast('Data Di Save')
      //  let key = this.KeteranganAbsen.find(x => x.IdKaryawan == item.IdKaryawan && )
      //  let find = obj.filter
      api.put("/keteranganabsenperiode"+'/'+bulan+'/'+tahun+'/?token='+this.token,{
            IdKaryawan:item.IdKaryawan,
            key:key,
            text:text.toUpperCase(),
            data: obj,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              // this.getPeriode()
              // this.Toast('Data Di Save')
              // this.getPeriode(false)
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }else{
        this.ToastFail('Invalid! Pastikan Menggunakan Capslock. Perhatikan Kembali Apa yang anda input => '+text+' <= adalah ilegal karakter ')
        this.getPeriode()
      }
       
      // this.TextEditor = false
    },
    aw(data){
      data.forEach(x => {
        x.Nama = x.karyawan.Nama
      });
      return data
    },
    paginate (arr, size) {
      return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / size)
        let page = acc[idx] || (acc[idx] = [])
        page.push(val)

        return acc
      }, [])
    },
    paginasi(){
      return this.paginate(this.RealKeteranganAbsen,15)
      // console.log(this.paginate(this.RealKeteranganAbsen,10))
    },
    pencarian(data){
      // console.log(data)
      // let datas = this.KeteranganAbsen
        let datax = this.aw(this.RealKeteranganAbsen)
        const filterItems = (needle, heystack) => {
        let query = needle.toLowerCase();
        return heystack.filter(x => x.Nama.toLowerCase().indexOf(query) >= 0);
      }
      this.KeteranganAbsen = data != '' ? filterItems(data,datax) : data.length == 0 ? this.RealKeteranganAbsen : filterItems(data,datax)
      
      // console.log(datax)
      // let find = datax.indexOf(data)
      // let datax = this.KeteranganAbsen.find(x => x. == data)
      // console.log(datax)
    },
    EditCol(item){
      console.log(item.length)
      this.KeteranganAbsenSelected = item
      // console.log(this.KeteranganAbsenSelected)
      this.TextEditor = true
    },
    getHari(limit){
      const arrbulan = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let x1 = this.PeriodeTahun+'-'+this.PeriodeBulanIni+'-01'
      let x2 = new Date(x1)
      x2.setDate(0);
      x2.setDate(1);
      let cs = x2.getMonth()+1
      let ys = x2.getFullYear()
      this.PeriodeBulanLalu =  cs
      this.PeriodeTahunLalu = ys
      // console.log('blnlalu',this.PeriodeBulanLalu)
       Date.prototype.addDays = function(days) {
       var dat = new Date(this.valueOf())
       dat.setDate(dat.getDate() + days);
       return dat;
      }
      let PeriodeTahunDari = ys
      let PeriodeTahunsub = this.PeriodeTahun.substr(2,2)
      let PeriodeTahunDarisub = x2.getFullYear().toString().substr(-2)
      let DateAkhir = cs == 12 ? '-26' : '-25'
      // let PeriodeBulan = this.PeriodeBulan
      let DariTanggal = PeriodeTahunDari+'-'+this.PeriodeBulanLalu+'-26'
      let SampaiTanggal = this.PeriodeTahun+'-'+this.PeriodeBulanIni+DateAkhir
      this.PeriodeTextDariTanggal = '26-'+arrbulan[x2.getMonth()]+'-'+PeriodeTahunDarisub
      this.PeriodeTextSampaiTanggal = '25-'+arrbulan[this.PeriodeBulanIni-1]+'-'+PeriodeTahunsub
      const DariTanggalRaw = new Date(DariTanggal);
      const SampaiTanggalRaw = new Date(SampaiTanggal);
      var HariPertama = new Date(DariTanggalRaw);
      var HariTerakhir = new Date(SampaiTanggalRaw);
      // var HariPertama = new Date(Tanggal.getFullYear(), Tanggal.getMonth(), 1);
      // var HariTerakhir = new Date(Tanggal.getFullYear(), Tanggal.getMonth() + 1, 0);
      var tanggalArray = new Array();
        function getDates(startDate, stopDate) {
            var tanggalSekarang = startDate;
            while (tanggalSekarang <= stopDate) {
              tanggalArray.push(tanggalSekarang)
              tanggalSekarang = tanggalSekarang.addDays(1);
            }
            return tanggalArray;
          }
      let data = []
      let hars = []
      var hari = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      var AntaraTanggal = getDates(new Date(HariPertama), (new Date(HariTerakhir)));
      for (let i = 0; i < AntaraTanggal.length; i++ ) {
          hars.push(('0' + tanggalArray[i].getDate()).slice(-2) + '-'+ ('0' + (tanggalArray[i].getMonth()+1)).slice(-2))
          data.push(hari[tanggalArray[i].getDay()]);
      }
      hars = hars.slice(limit)
      data = data.slice(limit)
      this.DataTanggalBulan = hars
      this.DataHari = data
      this.OverlayProses = false
    },
    getHeaderTbl(state,data){
      let val = data - 95
      var tb = document.querySelectorAll('.headertbl')
      if (state == true) {
        for (let elem of tb) {
          elem.style.transform = "translateY("+val+"px)"
          elem.style.backgroundColor = localStorage.getItem('darktheme') == 'true' ? 'black' : 'white'
          elem.style.color = localStorage.getItem('darktheme') == 'true' ? 'white' : 'black'
          elem.classList.add("HeaderFix")
        }
      }else{
        for (let elem of tb) {
          elem.classList.remove("HeaderFix");
          elem.style.removeProperty('transform')
          elem.style.removeProperty('zIndex')
        }
      }
    },
    getSideTbl(state,data){
      let val = data - 35
      var tb = document.querySelectorAll('.sidetbl')
      if (state == true) {
        for (let elem of tb) {
          elem.style.transform = "translateX("+val+"px)"
          elem.classList.add("SideFix")
        }
      }else{
        for (let elem of tb) {
          elem.classList.remove("SideFix");
          elem.style.removeProperty('transform')
        }
      }
    },
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },
    ToastFail(data){
      this.$swal.fire({
      toast: true,
      icon: 'error',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },
    tes(data){
      if (data.target.scrollLeft > 600) {
          this.scrolledY = true
          this.getSideTbl(true,data.target.scrollLeft)
      } else {
          this.scrolledY = false
          this.getSideTbl(false,0)
      }
      if (data.target.scrollTop > 170) {
          this.scrolledY = true
          this.getHeaderTbl(true,data.target.scrollTop)
      } else {
          this.scrolledY = false
          this.getHeaderTbl(false,0)
      }
    },

    GeneratorTahun() {
        var sekarang = new Date().getFullYear()
        var perusahaanberdiri = sekarang - 2008
        var hitungan = sekarang - perusahaanberdiri
        var tahun = []

        for (var i = sekarang; i >= hitungan; i--) {
          tahun.push(i.toString())
        }
        this.AllTahun = tahun
      },

    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitlePerusahaan === "Tambah Data Perusahaan"){
          api.post('/perusahaan?token='+this.token,{
            KodePerusahaan: this.editedItem.KodePerusahaan,
            NamaPerusahaan: this.editedItem.NamaPerusahaan,
            Alamat: this.editedItem.Alamat,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitlePerusahaan === "Ubah Data Perusahaan"){
         api.put("/perusahaan/"+this.KodePerusahaan+'/?token='+this.token,{
            KodePerusahaan: this.editedItem.KodePerusahaan,
            NamaPerusahaan: this.editedItem.NamaPerusahaan,
            Alamat: this.editedItem.Alamat,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogPerusahaan()    
    },

    PdfExport(){
      let data = document.getElementById('perusahaanview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportPerusahaan.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridperusahaan.columns[0].visible = false;
      // this.$refs.gridperusahaan.columns[1].visible = true;
      this.$refs.gridperusahaan.pdfExport(data.pdfExportProperties);
    },
    infotable(){
      this.dialogInfo = true
    },
    KeteranganAbsenNomor(data){
      let final = []
      for (let i = 0; i < data.length; i++) {
        const x = data[i];
        x.No = i+1
        final.push(x)
      }
      return final
    },
    getPeriode(){
      // console.log(this.PeriodeTahun)
      this.PeriodeBulanIni = this.PeriodeBulan
      if (this.PeriodeBulanIni != 0 && this.PeriodeTahun != null) {
        this.OverlayProses = true
        // let tgl = this.PeriodeTahun+'-'+this.PeriodeBulan+'-1'
        //  var x = new Date(this.PeriodeTahun+'-'+this.PeriodeBulan+'-01');
        //   x.setDate(1);
        //   x.setMonth(x.getMonth()-1);
        //   let c = x.getMonth()+1
        api.get('/keteranganabsenperiode/'+this.PeriodeBulanIni+'/'+this.PeriodeTahun+'?token='+this.token).then(res=>{
          if(res.data.length > 0){
          let PeriodeBulanLalu = this.PeriodeBulanIni == 1 ? 12 : parseInt(this.PeriodeBulanIni)-1
          console.log(res.data)
          this.RealKeteranganAbsen = this.KeteranganAbsenNomor(res.data)
          let paging = this.paginasi()
          this.totalpage = paging.length > 0 ? paging.length : 0
          this.KeteranganAbsen = paging.length > 0 ? paging[0] : []
          let BulanLalu = Object.keys(res.data[0].tanggal[PeriodeBulanLalu]).length
          let BulanIni = Object.keys(res.data[0].tanggal[this.PeriodeBulanIni]).length
          // console.log(BulanLalu)
          // console.log(BulanIni)
          let limit = BulanLalu+BulanIni
          limit = '-'+limit
          // console.log(limit)
          this.getHari(limit)
          }else{
            this.OverlayProses = false
          }
        })
      }
    },

    getpayroll(){
      // var c = document.createElement('canvas');
      // var img = document.getElementById('GambarHeader');
      // c.height = img.naturalHeight;
      // c.width = img.naturalWidth;
      // var ctx = c.getContext('2d');
      // ctx.drawImage(img, 0, 0, c.width, c.height);
      // this.$refs.ImageRefs.src = c.toDataURL();
      // if (this.valid == true) {
        if (this.dariTanggal && this.sampaiTanggal != '') {
          this.OverlayProses = true
          api.get('/absenoutput/'+this.dariTanggal+'/'+this.sampaiTanggal+'?token='+this.token).then(res=>{
            console.log('res',res)
          if (res) {
            for (let index = 0; index < res.data.Absensi.length; index++) {
              const element = res.data.Absensi[index];
              element.Alpa = (element.Alpa == 0) ? '-' : element.Alpa;
              element.Izin = (element.Izin == 0) ? '-' : element.Izin;
              element.IzinResmi = (element.IzinResmi == 0) ? '-' : element.IzinResmi;
              element.Sakit = (element.Sakit == 0) ? '-' : element.Sakit;
              element.SakitDokter = (element.SakitDokter == 0) ? '-' : element.SakitDokter;
              element.SetengahHari = (element.SetengahHari == 0) ? '-' : element.SetengahHari;
              element.SB = (element.SB == 0) ? '-' : element.SB;
              element.Cuti = (element.Cuti == 0) ? '-' : element.Cuti;
              element.Libur = (element.Libur == 0) ? '-' : element.Libur;
              element.LiburBersama = (element.LiburBersama == 0) ? '-' : element.LiburBersama;
              element.OffSecurity = (element.OffSecurity == 0) ? '-' : element.OffSecurity;
              element.UangMakan = (element.UangMakan == 0) ? '-' : element.UangMakan;
              element.Score = (element.Score == 0) ? '-' : element.Score;
            }
            this.OverlayProses = false
            this.payrolls = res.data.Absensi
            }
         })
        }else{
          this.AlertTglPayroll = true
          this.OverlayProses = false
          this.pesan = "Pastikan seluruh tanggal terisi"
        }
        
      // }
    },

    getdata(){
      api.get('/keteranganabsen?token='+this.token,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
					// console.log(res);
          if (res) {
            // let No = 1
            // for (let i = 0; i < res.data.length; i++) {
            //   res.data[i].No = No
            //   No++
            // }
            this.KeteranganAbsen = res.data
            // console.log(res.data)
          // this.loading = false
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },
    cetakexcel(){
      // var header = document.getElementById('GambarHeader')
      // var saved = header.cloneNode(true)
      // header.remove()
      var tableId = 'container'
      var filename = 'Export '+this.dariTanggal+'-'+this.sampaiTanggal
          let dataType = 'application/vnd.ms-excel';
          let extension = '.xls';

          let base64 = function(s) {
              return window.btoa(unescape(encodeURIComponent(s)))
          };
          let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>';
          let render = function(template, content) {
              return template.replace(/{(\w+)}/g, function(m, p) { return content[p]; });
          };
          // console.log(render)
          let tableElement = document.getElementById(tableId);

          let tableExcel = render(template, {
              // imgsrc1 : this.$refs.ImageRefs.src,
              worksheet: filename,
              table: tableElement.innerHTML
          });

          filename = filename + extension;

          if (navigator.msSaveOrOpenBlob)
          {
              let blob = new Blob(
                  [ '\ufeff', tableExcel ],
                  { type: dataType }
              );

              navigator.msSaveOrOpenBlob(blob, filename);
          } else {
              let downloadLink = document.createElement("a");

              document.body.appendChild(downloadLink);

              downloadLink.href = 'data:' + dataType + ';base64,' + base64(tableExcel);

              downloadLink.download = filename;

              downloadLink.click();
          }
          // console.log(saved)
          // document.getElementById('TextHeader').appendChild(saved)
          // document.getElementById("GambarHeader").style.removeProperty('display');
    },
    cetakpdf(){
      let contain = document.getElementById('container')
      contain.style.height = 'auto'
      let tes = document.getElementsByTagName('tr')
      console.log(tes.length)
      for (const elem of document.querySelectorAll('.notprint')) {
          elem.style.display = 'none';
      }
        const prtHtml = contain.innerHTML;
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        // Buka the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var is_chrome = Boolean(WinPrint.chrome);
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        WinPrint.document.close();
        if (is_chrome) {
        WinPrint.onload = function() { // wait until all resources loaded 
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();  // change window to mywindow
            WinPrint.close();// change window to mywindow
            };
        }
        else {
            WinPrint.document.close(); //
            WinPrint.focus(); // n
            WinPrint.print();
            WinPrint.close();
        }
      contain.style.height = '700px'
      for (const elem of document.querySelectorAll('.notprint')) {
          elem.style.removeProperty('display')
      }
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertTglPayroll = false
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style>
#container{
height: 550px; 
width:100%; 
padding:4px;
/* width:587px; */
/* height:834px; */
/* overflow: scroll; */
overflow-y: auto;
white-space:nowrap;   
}
#container tr.header, #container tr:hover {
  background-color: #e0e0e0;
}
.scroller{
  height: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
table {
  border-collapse:collapse;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  page-break-inside:auto
}
th,td {
	border: 1px solid #000000;
  page-break-inside:avoid; page-break-after:auto
}
tr{
  page-break-inside:avoid; page-break-after:auto
}
/* td.NamaJabatan { font-size: 13px; } */
.absensi {
  text-align:right;
  padding-left:8px;
  padding-right:8px;
}
#absensi{
  text-align:right;
  padding-left:8px;
  padding-right:8px;
}
.border-top{
  border: none;
  border-top:1px solid black;
}
.border-right{
  border-right: 1px solid black;
}
.border-up-right{
  border:none;
  border-top:1px solid black;
  border-right: 1px solid black;
}
.border-mid-right{
  border:none;
  border-right: 1px solid black;
}
.border-mid-right{
  border:none;
  border-right: 1px solid black;
}
.border-bottom-right{
  border:none;
  border-right: 1px solid black;
}
.border-none{
  border:none;
}
.border-bottom{
  border:none;
  border-bottom:1px solid black;
}
.warna-ungu{
  background-color: #D60093;
}
.warna-H{
  background-color: rgb(146, 208, 80);
}
.warna-A{
  background-color: rgb(255, 0, 0);
  color: #fff !important;
}
.warna-I{
  background-color: rgb(226, 107, 10);
}
.warna-IR{
  background-color: rgb(250, 191, 143);
}
.warna-S{
  background-color: rgb(141, 180, 226);
}
.warna-SD{
  background-color: rgb(89, 89, 89);
}
.warna-1-2{
  background-color: rgb(255, 255, 255);
}
.warna-S-B{
  background-color: rgb(218, 150, 148);
}
.warna-CT{
  background-color: rgb(36, 64, 98);
}
.warna-L{
  background-color: rgb(0, 176, 240);
}
.warna-LB{
  background-color: rgb(112, 48, 160);
}
.warna-OFF{
  background-color: rgb(255, 255, 0);
}
.warna-RESIGN{
  background-color: rgb(255, 0, 0);
}
.text-center{
  text-align: center;
}
.text-nama{
  padding-left: 10px;
  padding-right: 10px;
}
.text-jabatan{
  padding-left: 10px;
  padding-right: 10px;
}
.TextEditor{
  max-width: 60px;
  text-align: center;
}
.vue-quick-edit {
  color: #000000;
  text-decoration: none;
  width: 62px !important;
  text-align: center;
}
.vue-quick-edit__link{
  color: #000000 !important;
}
.vue-quick-edit__link--is-clickable {
    border:none !important;
    cursor: pointer;
}
.vue-quick-edit__input{
  width: 62px;
  background-color: #dedede !important;
  text-align: center;
  border: 3px solid #3276b1 !important;
}
.vue-quick-edit__button--cancel{
  margin-left: -5px !important;
  border-color: none !important;
  color:white !important;
  background-color: #ac2121 !important;
}
.vue-quick-edit__button--ok{
  background-color: #12ca7e !important;
}
.vue-quick-edit__input:not(textarea):not([multiple]) + .vue-quick-edit__buttons, label + .vue-quick-edit__buttons {
    display:table-cell !important;
    margin-left: -4px !important;
}
.HeaderFix{
  /* position: fixed; */
  background-color: #fff;
}
.SideFix{
  /* position: fixed; */
  border-right: 1px solid black !important;
  border-left: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-top: 1px solid black !important;
  background-color: rgb(243, 243, 243);
}
/* .html2canvas-container { 
    width: 3000px; 
    height: 3000px; 
} */
</style>

